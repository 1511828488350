import { HttpApiError } from '@backmarket/http-api'

import { getRedirectionToPaymentResult } from '../helpers/getRedirection'
import { isErrorWithCustomData } from '../helpers/isErrorWithCustomData'

import { PaymentError, PaymentErrorSource } from './PaymentError'
import type { PaymentReadableMessage } from './PaymentReadableMessage'
import type { PaymentRedirection } from './PaymentRedirection'

export class PaymentSubmitError extends PaymentError {
  public redirection?: PaymentRedirection

  public readableMessage?: PaymentReadableMessage

  constructor({
    message,
    redirection,
    readableMessage,
    ...errorOptions
  }: {
    message?: string
    redirection?: PaymentRedirection
    readableMessage?: PaymentReadableMessage
  } & ConstructorParameters<typeof PaymentError>[1]) {
    super(message ?? 'Payment error', { ...errorOptions })

    this.redirection = redirection
    this.readableMessage = readableMessage
  }

  static fromAnyError(error: unknown | Error | HttpApiError) {
    if (error instanceof this) {
      return error
    }

    if (error instanceof HttpApiError) {
      return this.fromHttpApiError(error)
    }

    if (error instanceof PaymentError) {
      return new this({
        cause: error,
        message: error.message,
        type: error.type,
        source: error.source,
      })
    }

    return new this({
      cause: error,
      redirection: getRedirectionToPaymentResult(),
    })
  }

  static fromHttpApiError(error: HttpApiError) {
    const { nextAction, paymentId } = isErrorWithCustomData(error)
      ? error.backmarket
      : { nextAction: undefined, paymentId: undefined }

    switch (nextAction) {
      case 'SHOW_ERROR':
        return new this({
          cause: error,
          type: error.type,
          source: PaymentErrorSource.API_PROBLEM,
          message: error.message,
          readableMessage: {
            title: error.title ?? '',
            description: error.detail ?? '',
          },
        })

      default:
        return new this({
          cause: error,
          message: error.message,
          type: error.type,
          source: PaymentErrorSource.API_PROBLEM,
          redirection: getRedirectionToPaymentResult(paymentId),
        })
    }
  }
}
