import type { paymentAPI } from '@backmarket/http-api'
import type { I18n } from '@backmarket/nuxt-module-i18n/types'

import { isPaymentNetworkError } from '../../../form-common/helpers/isPaymentNetworkError'
import type { PaymentSetupError } from '../../../form-common/types/PaymentSetupError'
import { paymentMethodName } from '../../../methods'

import translations from './PaymentMethodFormBase.translations'

export const getSetupErrorMessage = (
  i18n: I18n,
  method: paymentAPI.PaymentMethod | null,
  error: PaymentSetupError,
) => {
  const values = {
    paymentMethod: method?.bmCode ? paymentMethodName(method.bmCode) : '',
  }

  if (error.readableMessage) {
    return error.readableMessage
  }

  if (isPaymentNetworkError(error)) {
    return {
      title: i18n(translations.defaultSetupErrorTitle, values),
      description: i18n(translations.genericNetworkErrorDescription),
    }
  }

  return {
    title: i18n(translations.defaultSetupErrorTitle, values),
    description: i18n(translations.defaultSetupErrorDescription, {
      ...values,
      reason: error.message || `${error}`,
    }),
  }
}
