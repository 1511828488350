<template>
  <RevAccordionList :has-external-borders="false">
    <RevAccordionItem
      v-for="group in paymentGroups"
      :key="group.id"
      :collapsed="groupCollapsedState(group)"
      :disabled="!isGroupEnabled(group)"
      tabindex="-1"
      :wrapper-attrs="{ 'data-qa': `payment-group-${group.id}` }"
      @click.prevent="handleGroupListItemClick(group)"
    >
      <template #image>
        <span></span>
      </template>
      <template #title>
        <PaymentMethodsErrors
          :inner-class="tw`flex items-center justify-between gap-1`"
          :payment-methods="group.methods"
        >
          <RevRadio
            :id="`payment-group-${group.id}`"
            :disabled="!isGroupEnabled(group)"
            :model-value="isGroupSelected(group)"
            :name="`payment-group-${group.id}`"
            :value="group.id"
          >
            <template #label>
              <RevSkeleton
                v-if="loading"
                :aria-label="i18n(translations.loading)"
                height="2rem"
                shape="rectangle"
                width="30rem"
              />

              <span v-if="!loading">
                {{ i18n(group.label) ?? group.id }}
              </span>
            </template>
          </RevRadio>

          <RevSkeleton
            v-if="loading"
            :aria-label="i18n(translations.loading)"
            height="20px"
            shape="rectangle"
            width="36px"
          />

          <PaymentIcons
            v-if="!loading"
            class="justify-end"
            :class="{ ['opacity-50 saturate-0']: !isGroupEnabled(group) }"
            :methods="group.methods"
            show-disabled-methods
          />
        </PaymentMethodsErrors>
      </template>
      <template #body>
        <div class="md:px-24" data-test="payment-method-body">
          <div
            v-if="group.hasManyEnabledMethods"
            class="mb-32 mt-12 grid grid-cols-2 gap-12"
          >
            <RevPicker
              v-for="method in group.enabledMethods"
              :key="method.bmCode"
              :data-qa="`payment-method-${method.bmCode}`"
              :data-test="`payment-method-${method.bmCode}`"
              :disabled="!method.enabled"
              :label="paymentMethodLabel(method.bmCode)"
              :selected="method.reference === modelValue?.reference"
              @select="handleMethodPickerClick(method)"
            />
          </div>

          <slot />
        </div>
      </template>
    </RevAccordionItem>
  </RevAccordionList>
</template>

<script lang="ts" setup>
import { toRef } from 'vue'

import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { tw } from '@backmarket/utils/string/tw'
import {
  type CollapsedStates,
  RevAccordionItem,
} from '@ds/components/AccordionItem'
import { RevAccordionList } from '@ds/components/AccordionList'
import { RevPicker } from '@ds/components/Picker'
import { RevRadio } from '@ds/components/Radio'
import { RevSkeleton } from '@ds/components/Skeleton'

import type { PaymentGroupData } from '../../config/methods.type'
import { paymentMethodLabel } from '../../helpers/methods'
import PaymentIcons from '../PaymentIcons/PaymentIcons.vue'
import PaymentMethodsErrors from '../PaymentMethodsErrors/PaymentMethodsErrors.vue'

import translations from './PaymentMethodsList.translations'

const i18n = useI18n()

const props = defineProps<{
  paymentGroups: PaymentGroupData[]
  loading: boolean
  disabled: boolean
  modelValue: PaymentMethod | null
}>()

const emit = defineEmits<{
  'update:modelValue': [modelValue: PaymentMethod | null]
}>()

const selectedPaymentMethod = toRef(props, 'modelValue')

const handleGroupListItemClick = (group: PaymentGroupData) =>
  emit('update:modelValue', group.enabledMethods[0])

const handleMethodPickerClick = (method: PaymentMethod) =>
  emit('update:modelValue', method)

const isGroupSelected = (group: PaymentGroupData) =>
  !props.disabled && group.id === selectedPaymentMethod.value?.group

const groupCollapsedState = (group: PaymentGroupData): CollapsedStates =>
  isGroupSelected(group) ? 'expanded' : 'collapsed'

const isGroupEnabled = (group: PaymentGroupData) =>
  group.isEnabled && !props.loading && !props.disabled
</script>
