import { type MaybeRefOrGetter, onMounted, toRef, toValue, watch } from 'vue'

import { type HttpApiError, paymentAPI } from '@backmarket/http-api'
import type { GetNextActionResponse } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import type { UseHttpFetchOptions } from '@backmarket/nuxt-module-http/UseHttpFetchOptions'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

/**
 * Automatically get the next action via `useHttpFetch` when the payment id changes.
 * When the payment id is not defined, it does not fetch anything.
 */
export const usePaymentNextAction = (
  paymentId: MaybeRefOrGetter<string | undefined>,
  options: UseHttpFetchOptions<GetNextActionResponse, HttpApiError>,
) => {
  const httpFetchResult = useHttpFetch(paymentAPI.getNextAction, {
    ...options,
    immediate: false,
    pathParams: {
      paymentId: toRef(paymentId),
    },
  })

  // Only fetch the next action on the client for now
  // TODO: [PAYIN-3803] Study how to fetch the next action on the server for better performances
  onMounted(() => {
    if (toValue(paymentId)) {
      void httpFetchResult.execute()
    }
  })

  // If for some reason the payment id changes, we should fetch the next action again
  watch(toRef(paymentId), (newPaymentId, oldPaymentId) => {
    if (newPaymentId && newPaymentId !== oldPaymentId) {
      void httpFetchResult.execute()
    }
  })

  return httpFetchResult
}
