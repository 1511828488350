import { type Ref, computed, ref } from 'vue'

import AdyenCheckout from '@adyen/adyen-web'
import type {
  AdyenCardBrands,
  AdyenCardPaymentMethodConfig,
} from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'

import { buildVirtualFormState } from '../../form-common/helpers/buildVirtualCardFormState'
import type { VirtualCardFieldIds } from '../../form-common/types/VirtualCardFieldIds'
import { getAdyenCardFieldId } from '../helpers/getAdyenCardFieldId'
import type {
  AdyenBinLookupPayload,
  AdyenChangePayload,
  AdyenErrorPayload,
  AdyenFocusPayload,
} from '../types/AdyenEventPayload'

import '@adyen/adyen-web/dist/adyen.css'

import { useAdyenCardFormValidation } from './useAdyenCardFormValidation'

export const ADYEN_COMPONENT_STYLES = {
  base: {
    color: '#111111',
    fontSize: '13px',
    fontWeight: '300',
    display: 'block',
    lineHeight: '2',
    padding: '18px 12px 0 12px',
  },
}

/**
 * Regroups all the Adyen virtual form logic
 */
export function useAdyenCardForm() {
  const locale = useI18nLocale()

  let checkoutInstance: Awaited<ReturnType<typeof AdyenCheckout>> | null = null
  const lastAdyenChangePayload: Ref<AdyenChangePayload | null> = ref(null)

  // Because of the placeholder set by Adyen, we consider the fields as always filled
  const virtualForm = buildVirtualFormState()
  virtualForm.number.filled = true
  virtualForm.securityCode.filled = true
  virtualForm.expiryDate.filled = true

  let isAdyenErrorsVisible = false
  const { validateForm } = useAdyenCardFormValidation(
    lastAdyenChangePayload,
    () => virtualForm,
  )

  const handleFocusPayloadFor = (
    field: VirtualCardFieldIds,
    payload?: AdyenFocusPayload,
  ) => {
    const isFocused = payload?.currentFocusObject === getAdyenCardFieldId(field)
    virtualForm[field].focused = isFocused
  }

  const availableAdyenBrands = ref<AdyenCardBrands[]>([])

  return {
    virtualForm,
    availableAdyenBrands,
    data: computed(() => ({
      encryptedData: lastAdyenChangePayload.value?.data?.paymentMethod,
      browserInfo: lastAdyenChangePayload.value?.data?.browserInfo,
    })),
    async setupForm(
      config: AdyenCardPaymentMethodConfig,
      container: HTMLElement,
    ) {
      checkoutInstance = await AdyenCheckout({
        locale,
        clientKey: config.publicKey,
        environment: config.environment,
      })

      await new Promise<void>((resolve, reject) => {
        const component = checkoutInstance?.create('securedfields', {
          type: 'card',
          styles: ADYEN_COMPONENT_STYLES,
          // Get adyen supported brand from config.
          // If the config is not set this will default to ['mc','visa','amex']
          // https://docs.adyen.com/payment-methods/cards/custom-card-integration#supported-card-types
          brands: config?.supportedBrands,

          onConfigSuccess: () => {
            resolve()
          },

          onError: (payload: AdyenErrorPayload) => {
            reject(Object.assign(new Error(payload?.error), { payload }))
          },

          onBinLookup: (payload: AdyenBinLookupPayload) => {
            availableAdyenBrands.value = payload?.supportedBrands || []
          },

          onChange: (payload: AdyenChangePayload) => {
            lastAdyenChangePayload.value = payload

            if (isAdyenErrorsVisible) {
              validateForm()
            }
          },

          onFocus: (payload: AdyenFocusPayload) => {
            handleFocusPayloadFor('number', payload)
            handleFocusPayloadFor('expiryDate', payload)
            handleFocusPayloadFor('securityCode', payload)
          },
        })

        component?.mount(container)
      })
    },
    /**
     * Validate the form and show errors
     */
    validateForm: () => {
      isAdyenErrorsVisible = true

      return validateForm()
    },
  }
}
