import { computed, ref } from 'vue'

import type { PaymentNetwork } from '@backmarket/http-api/src/api-specs-payment/payment'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import type {
  CardFieldCvcInputEvent,
  CardFieldExpInputEvent,
  CardFieldNumberInputEvent,
  CardFieldStyle,
  CardForm,
  ProcessOutClient,
  ProcessOutException,
} from '../../../processout'
import { buildVirtualFormState } from '../../form-common/helpers/buildVirtualCardFormState'
import { PaymentSubmitError } from '../../form-common/types/PaymentSubmitError'
import { VirtualCardFieldIds } from '../../form-common/types/VirtualCardFieldIds'
import { PROCESSOUT_VALIDATION_ERROR_TARGETS } from '../config/errors'
import { getExceptionReasonMessage } from '../helpers/getExceptionReadableMessage'
import { isProcessOutException } from '../helpers/isProcessOutException'
import { ProcessOutCardFieldIds } from '../types/ProcessOutCardFieldIds'
import {
  PROCESSOUT_CARD_NETWORK_ID_BY_SCHEME,
  ProcessoutCardSchemes,
} from '../types/ProcessOutCardSchemes'

import { requestFocus } from './useProcessOutForm.utils'
import { useProcessOutLibrary } from './useProcessOutLibrary'

const PROCESSOUT_CARD_FIELDS_STYLE: CardFieldStyle = {
  color: '#111111',
  // TODO [PAYIN-2362] Request custom font support to ProcessOut
  fontFamily: 'BodyFont, sans-serif',
  fontSize: '14px',
  fontWeight: '300',
  '::placeholder': {
    color: '#111111',
  },
  ':focus::placeholder': {
    color: '#111111',
  },
}

export function useProcessOutForm() {
  const { getProcessOutLibrary } = useProcessOutLibrary()
  const i18n = useI18n()

  let client: ProcessOutClient | null = null
  let form: CardForm | null = null

  const virtualForm = buildVirtualFormState({
    focus: (id) => requestFocus(id, () => form),
  })
  const availableProcessOutSchemes = ref<ProcessoutCardSchemes[]>()
  const availableNetworks = computed(
    () =>
      (availableProcessOutSchemes.value ?? [])
        .map((scheme) => PROCESSOUT_CARD_NETWORK_ID_BY_SCHEME[scheme])
        .filter(Boolean) as PaymentNetwork[],
  )

  const handleFocusFor = (field: VirtualCardFieldIds) => () => {
    virtualForm[field].focused = true
  }

  const handleBlurFor = (field: VirtualCardFieldIds) => () => {
    virtualForm[field].focused = false
  }

  const touchField = (field: VirtualCardFieldIds) => {
    // For simplicity, we reset the error state when the field is touched
    virtualForm[field].touched = true
    virtualForm[field].invalid = false
    virtualForm[field].error = undefined
  }

  const resetErrors = () => {
    Object.values(VirtualCardFieldIds).forEach((fieldId) => {
      const field = virtualForm[fieldId]
      if ('invalid' in field) {
        field.invalid = false
      }
      field.error = undefined
    })
  }

  const handleValidationError = (
    fieldId: VirtualCardFieldIds,
    error: ProcessOutException,
  ) => {
    const field = virtualForm[fieldId]

    if ('invalid' in field) {
      field.invalid = true
    }
    field.error = getExceptionReasonMessage(i18n, error)
  }

  const handleDateInput = (event: CardFieldExpInputEvent) => {
    virtualForm.expiryDate.filled = event.month > 0 || event.year > 2000
    touchField('expiryDate')
  }

  const handleSecurityCodeInput = (event: CardFieldCvcInputEvent) => {
    virtualForm.securityCode.filled = event.cvc_length > 0
    touchField('securityCode')
  }

  const handleNumberInput = (event: CardFieldNumberInputEvent) => {
    virtualForm.number.filled = event.card_number_length > 0
    availableProcessOutSchemes.value = virtualForm.number.filled
      ? event.schemes || []
      : []
    touchField('number')
  }

  return {
    virtualForm,
    availableNetworks,
    setupForm: async (publicId: string, container: HTMLElement) => {
      const { ProcessOut } = await getProcessOutLibrary()

      client = new ProcessOut(publicId)

      form = await new Promise((resolve, reject) => {
        client?.setupForm(
          container,
          {
            requireCVC: false,
            style: PROCESSOUT_CARD_FIELDS_STYLE,
          },
          resolve,
          reject,
        )
      })

      if (form) {
        form.getNumberField().on('input', handleNumberInput)
        form.getCVCField().on('input', handleSecurityCodeInput)
        form.getExpiryField().on('input', handleDateInput)

        form.getExpiryField().on('focus', handleFocusFor('expiryDate'))
        form.getExpiryField().on('blur', handleBlurFor('expiryDate'))

        form.getCVCField().on('focus', handleFocusFor('securityCode'))
        form.getCVCField().on('blur', handleBlurFor('securityCode'))

        form.getNumberField().on('focus', handleFocusFor('number'))
        form.getNumberField().on('blur', handleBlurFor('number'))
      }
    },
    tokenizeForm: async ({
      name,
      selectedNetwork,
    }: {
      name: string
      selectedNetwork?: PaymentNetwork | null
    }) => {
      // Reset errors, tokenization is a new attempt that will raise new errors if any.
      resetErrors()

      try {
        return await new Promise<string>((resolve, reject) => {
          if (!client || !form) {
            reject(new Error('PO Form or client not initialized'))

            return
          }

          // We find the selected brand back from availableProcessOutSchemes,
          // because ADYEN_CARD_NETWORK_ID_BY_BRAND may contain multiple brand
          // sharing the same network.
          const selectedProcessOutScheme = selectedNetwork
            ? availableProcessOutSchemes.value?.find(
                (brand) =>
                  PROCESSOUT_CARD_NETWORK_ID_BY_SCHEME[brand] ===
                  selectedNetwork,
              ) ?? null
            : null

          client?.tokenize(
            form,
            {
              name,
              ...(selectedProcessOutScheme && {
                preferred_scheme: selectedProcessOutScheme,
              }),
            },
            resolve,
            reject,
          )
        })
      } catch (error) {
        if (
          isProcessOutException(error) &&
          error.code in PROCESSOUT_VALIDATION_ERROR_TARGETS
        ) {
          const fieldId =
            PROCESSOUT_VALIDATION_ERROR_TARGETS[error.code] ??
            ProcessOutCardFieldIds.NAME

          handleValidationError(fieldId, error)

          // Throw a PaymentSubmitError without any redirection or message.
          // We want to force a 'submit-error' event without showing anything
          // to the user outside of the validation errors under the fields
          throw new PaymentSubmitError({
            cause: error,
            message: error.message,
            readableMessage: undefined,
            redirection: undefined,
            source: 'FRONT_LIBRARY_PROCESSOUT',
            type: '/errors/payment/user/invalid-input',
          })
        }
        throw error
      }
    },

    // TODO [PAYIN-3697] Move this logic to a dedicated composable/helpers
    makeCardPayment: async (
      invoiceId: string,
      token: string,
      options: { authorize_only?: boolean },
    ) => {
      return new Promise<void>((resolve, reject) => {
        if (!client || !form) {
          reject(new Error('PO Form or client not initialized'))

          return
        }

        client.makeCardPayment(invoiceId, token, options, resolve, reject)
      })
    },
  }
}
