import { HttpApiError, paymentAPI } from '@backmarket/http-api'

type ErrorWithCustomData = HttpApiError & {
  backmarket: {
    nextAction?: paymentAPI.NextAction
    paymentId?: string
  }
}

export const isErrorWithCustomData = (
  error: HttpApiError,
): error is ErrorWithCustomData => {
  return 'backmarket' in error
}
