<template>
  <component
    :is="adapter"
    :id="id"
    ref="instance"
    :base-price="basePrice"
    :create-payment="createPayment"
    :disabled="isDisabled"
    :payment-method="paymentMethod"
    @setup-error="onSetupError"
    @setup-start="onSetupStart"
    @setup-success="onSetupSuccess"
    @submit-error="onSubmitError"
    @submit-start="onSubmitStart"
    @submit-success="onSubmitSuccess"
  />
</template>

<script lang="ts" setup>
import { computed, ref, shallowRef, toRef, watch } from 'vue'

import type {
  PaymentFormAdapterFullProps as AdapterProps,
  PaymentFormAdapter,
  PaymentFormAdapterEmits,
  PaymentFormAdapterInstance,
  PaymentFormAdapterProps,
} from '../../../../form-common/types'

const props = defineProps<
  {
    adapter: PaymentFormAdapter
  } & PaymentFormAdapterProps
>()

const emit = defineEmits<PaymentFormAdapterEmits>()
const instance = shallowRef<PaymentFormAdapterInstance | null>(null)

const isSettingUp = ref(false)
const isSubmitting = ref(false)
const isBusy = computed(() => isSettingUp.value || isSubmitting.value)
const isDisabled = computed(() => props.disabled || isBusy.value)

watch(toRef(props, 'adapter'), () => {
  // TODO: [PAYIN-3847] This state could be managed by the adapter,
  // and adapters should be kept alive between payment method changes
  isSettingUp.value = false
  isSubmitting.value = false
})

const onSetupStart: AdapterProps['onSetupStart'] = (...args) => {
  isSettingUp.value = true
  emit('setup-start', ...args)
}

const onSetupSuccess: AdapterProps['onSetupSuccess'] = (...args) => {
  isSettingUp.value = false
  emit('setup-success', ...args)
}

const onSetupError: AdapterProps['onSetupError'] = (...args) => {
  isSettingUp.value = false
  emit('setup-error', ...args)
}

const onSubmitStart: AdapterProps['onSubmitStart'] = (...args) => {
  isSubmitting.value = true
  emit('submit-start', ...args)
}

const onSubmitSuccess: AdapterProps['onSubmitSuccess'] = (...args) => {
  isSubmitting.value = false
  emit('submit-success', ...args)
}

const onSubmitError: AdapterProps['onSubmitError'] = (...args) => {
  isSubmitting.value = false
  emit('submit-error', ...args)
}

defineExpose({
  instance,
  isBusy,
  isDisabled,
})
</script>
