<template>
  <component
    :is="AdapterButton ?? RevButton"
    :disabled="isDisabled"
    :form="formId"
    full-width="responsive"
    :loading="isBusy"
    type="submit"
    variant="primary"
    v-bind="buttonProps"
  >
    {{ i18n(translations.submitButton) }}
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'

import type { PaymentMethodFormInstance } from '../../../form-common/types/PaymentMethodFormInstance'

import { getPaymentSubmitButtonDataQAAttributes } from './PaymentSubmitButton.helpers'
import translations from './PaymentSubmitButton.translations'

const i18n = useI18n()

const props = defineProps<{
  form: PaymentMethodFormInstance | null
  loading?: boolean
}>()

const AdapterButton = computed(
  () => props.form?.adapterInstance?.submitButton?.is,
)
const adapterProps = computed(
  () => props.form?.adapterInstance?.submitButton?.props ?? {},
)
const buttonProps = computed(() => ({
  ...adapterProps.value,
  ...getPaymentSubmitButtonDataQAAttributes(props.form?.method ?? null),
}))

const formId = computed(() => props.form?.formId)
const isBusy = computed(() => props.form?.isBusy ?? props.loading ?? false)
const isDisabled = computed(
  () => (isBusy.value || !props.form || props.form.isDisabled) ?? false,
)
</script>
