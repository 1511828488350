import { useRuntimeConfig } from '#imports'
import { type MaybeRefOrGetter, toValue } from 'vue'

import { type Price, paymentAPI as api } from '@backmarket/http-api'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useAuthStore } from '@backmarket/nuxt-module-oauth/useAuthStore'
import type { EventData } from '@backmarket/nuxt-module-tracking'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import {
  getDuration,
  isDurationKey,
} from '../../../../utils/performance/getDuration'
import type { PaymentLogTypes } from '../../form-common/types'
import { isPaymentMethodEnabled } from '../../methods'

// Events that need to be tracked with Noodle
const NOODLE_EVENTS: PaymentLogTypes[] = [
  'PAYMENT_FORM_DISPLAYED',
  'PAYMENT_FORM_METHOD_SELECTED',
  'PAYMENT_FORM_SUBMIT_START',
  'PAYMENT_FORM_SUBMIT_SUCCESS',
  'PAYMENT_FORM_SUBMIT_ERROR',
]

export type TrackingData = {
  basePrice: MaybeRefOrGetter<Price>
  selectedPaymentMethod: MaybeRefOrGetter<api.PaymentMethod | null>
  availablePaymentMethods?: MaybeRefOrGetter<api.PaymentMethod[]>
  defaultPaymentMethod?: MaybeRefOrGetter<api.PaymentMethod | null>
  isPaymentRequired?: MaybeRefOrGetter<boolean>
}

export const usePaymentFormEventTracker = (
  reactiveOptions: MaybeRefOrGetter<TrackingData>,
) => {
  const { userId: clientId } = useAuthStore()
  const logger = useLogger()
  const {
    public: { FF_NOODLE_PAYMENT_EVENTS_ENABLED },
  } = useRuntimeConfig()
  const { trackPaymentEvent } = useTracking()
  const trackBuilder =
    (type: PaymentLogTypes, message: string) =>
    (data?: Record<string, unknown>) => {
      const options = toValue(reactiveOptions)
      const basePrice = toValue(options.basePrice)
      const defaultPaymentMethod = toValue(options.defaultPaymentMethod)
      const selectedPaymentMethod = toValue(options.selectedPaymentMethod)
      const availablePaymentMethods = toValue(options.availablePaymentMethods)
      const required = toValue(options.isPaymentRequired)

      const duration = isDurationKey(type) ? getDuration(type) : null

      const payload = {
        clientId,
        payment: {
          amountAfterDiscount: basePrice,
          defaultMethod: defaultPaymentMethod,
          method: selectedPaymentMethod,
          methods: availablePaymentMethods?.reduce(
            (acc, method) => ({ ...acc, [method.bmCode]: method }),
            {},
          ),
          enabledMethods: availablePaymentMethods?.reduce<
            api.PaymentMethodCode[]
          >(
            (acc, method) =>
              isPaymentMethodEnabled(method) ? [...acc, method.bmCode] : acc,
            [],
          ),
          required,
          ...(duration !== null ? { duration } : {}),
          ...data,
        },
        type,
      }

      logger.info(`[PAYMENT] ${message}`, payload)

      if (FF_NOODLE_PAYMENT_EVENTS_ENABLED && NOODLE_EVENTS.includes(type)) {
        // TODO: fix EventData type https://backmarket.atlassian.net/browse/FRONT-953
        trackPaymentEvent(payload as EventData)
      }
    }

  const track = (
    type: PaymentLogTypes,
    message: string,
    data?: Record<string, unknown>,
  ) => trackBuilder(type, message)(data)

  // Create shortcuts when possible to reduce the boilerplate in the PaymentForm
  track.formDisplayed = trackBuilder(
    'PAYMENT_FORM_DISPLAYED',
    'Payment form displayed',
  )
  track.setupStart = trackBuilder(
    'PAYMENT_FORM_SETUP_START',
    'Form setup start',
  )
  track.setupSuccess = trackBuilder(
    'PAYMENT_FORM_SETUP_SUCCESS',
    'Form setup success',
  )
  track.setupError = trackBuilder(
    'PAYMENT_FORM_SETUP_ERROR',
    'Form setup error',
  )
  track.submitStart = trackBuilder(
    'PAYMENT_FORM_SUBMIT_START',
    'Form submit start',
  )
  track.submitSuccess = trackBuilder(
    'PAYMENT_FORM_SUBMIT_SUCCESS',
    'Form submit success',
  )
  track.submitError = trackBuilder(
    'PAYMENT_FORM_SUBMIT_ERROR',
    'Form submit error',
  )
  track.resumeStart = trackBuilder(
    'PAYMENT_FORM_RESUME_START',
    'Form resume start',
  )
  track.resumeSuccess = trackBuilder(
    'PAYMENT_FORM_RESUME_SUCCESS',
    'Form resume success',
  )
  track.resumeError = trackBuilder(
    'PAYMENT_FORM_RESUME_ERROR',
    'Form resume error',
  )

  return track
}
