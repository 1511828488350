<template>
  <div ref="container">
    <VirtualCardForm
      :id="id"
      :disabled="props.disabled"
      :virtual-form="virtualForm"
      @submit="handleSubmit"
    >
      <template #number>
        <AdyenVirtualInput
          :disabled="props.disabled"
          :label="i18n(translations.numberLabel)"
          v-bind="virtualForm.number"
        >
          <template #icon>
            <PaymentNetworkRadioGroup
              v-model="selectedNetwork"
              :disabled="props.disabled"
              :networks="availableNetworks"
              :preferred-networks="preferredNetworks"
            />
          </template>
        </AdyenVirtualInput>
      </template>

      <template #expiryDate>
        <AdyenVirtualInput
          :disabled="props.disabled"
          :icon="IconCalendar"
          :label="i18n(translations.dateLabel)"
          v-bind="virtualForm.expiryDate"
        />
      </template>

      <template #securityCode>
        <AdyenVirtualInput
          :disabled="props.disabled"
          :icon="IconLock"
          :label="i18n(translations.securityCodeLabel)"
          v-bind="virtualForm.securityCode"
        />
      </template>
    </VirtualCardForm>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'

import { paymentAPI } from '@backmarket/http-api'
import type {
  AdyenCardPaymentMethodConfig,
  PaymentNetwork,
} from '@backmarket/http-api/src/api-specs-payment/payment'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { IconCalendar } from '@ds/icons/IconCalendar'
import { IconLock } from '@ds/icons/IconLock'

import PaymentNetworkRadioGroup from '../../../form-common/components/PaymentNetworkRadioGroup/PaymentNetworkRadioGroup.vue'
import VirtualCardForm from '../../../form-common/components/VirtualCardForm.vue'
import { getRedirection } from '../../../form-common/helpers/getRedirection'
import {
  type PaymentFormAdapterEmits,
  type PaymentFormAdapterProps,
  PaymentSetupError,
  PaymentSubmitError,
} from '../../../form-common/types'
import type { VirtualCardConcreteValue } from '../../../form-common/types/VirtualCardFormState'
import { useAdyenCardForm } from '../../composables/useAdyenCardForm'
import { ADYEN_CARD_NETWORK_ID_BY_BRAND } from '../../types/AdyenCardBrands'

import translations from './AdyenCardAdapter.translations'
import AdyenVirtualInput from './AdyenVirtualInput.vue'

const props = defineProps<PaymentFormAdapterProps>()
const emit = defineEmits<PaymentFormAdapterEmits>()

const i18n = useI18n()

const { setupForm, virtualForm, validateForm, data, availableAdyenBrands } =
  useAdyenCardForm()
const container = ref<HTMLElement | null>(null)

// Handle co-branded cards
const preferredNetworks = computed(() => {
  const config = props.paymentMethod?.config ?? {}

  return ('preferredNetworks' in config ? config.preferredNetworks : []) ?? []
})
const availableNetworks = computed(() =>
  availableAdyenBrands.value
    .map((brand) => ADYEN_CARD_NETWORK_ID_BY_BRAND[brand])
    .filter(Boolean),
)
const selectedNetwork = ref<PaymentNetwork | null>(null)
const selectedAdyenBrand = computed(() => {
  // We find the selected brand back from availableAdyenBrands, because
  // ADYEN_CARD_NETWORK_ID_BY_BRAND may contain multiple brand sharing the
  // same network (ex: `maestro` + `maestrouk`)
  return selectedNetwork.value === null
    ? null
    : availableAdyenBrands.value.find(
        (brand) =>
          ADYEN_CARD_NETWORK_ID_BY_BRAND[brand] === selectedNetwork.value,
      )
})

// Setup the form
onMounted(async () => {
  if (container.value === null) {
    throw new Error('PO container not found')
  }

  emit('setup-start')

  try {
    await setupForm(
      props.paymentMethod?.config as AdyenCardPaymentMethodConfig,
      container.value,
    )

    emit('setup-success')
  } catch (error) {
    const setupError = PaymentSetupError.fromAnyError(error)

    emit('setup-error', setupError)
  }
})

// Submit the form
const handleSubmit = async (values: VirtualCardConcreteValue) => {
  if (!validateForm()) {
    return
  }
  emit('submit-start')

  try {
    const { encryptedData, browserInfo } = data.value
    if (isEmpty(encryptedData) || isEmpty(browserInfo)) {
      throw new Error('Invalid payment form data')
    }

    // 1. Create the payment
    const { paymentId } = await props.createPayment()

    // 2. Send the encrypted card data to the server via: /payment/confirm/card/:paymentId

    const payload = await $httpFetch(paymentAPI.postConfirmCard, {
      pathParams: { paymentId },
      body: {
        card: {
          encryptedCardNumber: encryptedData.encryptedCardNumber,
          encryptedExpiryMonth: encryptedData.encryptedExpiryMonth,
          encryptedExpiryYear: encryptedData.encryptedExpiryYear,
          encryptedSecurityCode: encryptedData.encryptedSecurityCode,
          holderName: values.name,
          ...(selectedAdyenBrand.value && {
            brand: selectedAdyenBrand.value,
          }),
        },
        browserInfo,
      },
    })

    // 3. Redirect to payment result OR /confirm/card nextAction.
    const redirection = getRedirection(payload.nextAction, paymentId)

    emit('submit-success', { paymentId, redirection })
  } catch (error) {
    emit('submit-error', PaymentSubmitError.fromAnyError(error))
  }
}
</script>
