<template>
  <RevModal
    :name="props.name"
    :title="i18n(translations.title)"
    variant="panel"
  >
    <template #body>
      <div class="mt-6">{{ i18n(translations.description) }}</div>
      <RevIllustration
        :alt="i18n(translations.description)"
        class="mx-auto mt-9 flex"
        :height="107"
        :src="toPublicPath('/img/payment/SecurityCode.svg')"
        :width="132"
      />
    </template>
  </RevModal>
</template>

<script setup lang="ts">
import { toPublicPath } from '@backmarket/nuxt-layer-base/utils/toPublicPath'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'
import { RevModal } from '@ds/components/Modal'

import translations from './CreditCardSecurityCodeModal.translations'

const i18n = useI18n()

const props = defineProps<{
  name: string
}>()
</script>
