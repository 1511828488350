<template>
  <RevForm
    :id="id"
    class="mt-12"
    :initial-values="initialValues"
    :name="id"
    :validate="validate"
    @submit="handleSubmit"
  >
    <template #default="{ values, errors }">
      <RevInputPhone
        id="phone-number"
        v-model="values.phone"
        clear-button-aria-label="clear"
        :default-country="country"
        description=""
        :error="errors.phone"
        :label="i18n(translations.phoneLabel)"
      />
    </template>
  </RevForm>
</template>

<script lang="ts" setup>
import { paymentAPI } from '@backmarket/http-api'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { sleep } from '@backmarket/utils/async/sleep'
import { RevForm, makeValidate, required } from '@ds/components/Form'
import { RevInputPhone, validPhoneNumber } from '@ds/components/InputPhone'

import { getRedirectionToPaymentResult } from '../../../form-common/helpers/getRedirection'
import {
  type PaymentFormAdapterEmits,
  type PaymentFormAdapterExpose,
  type PaymentFormAdapterProps,
  PaymentSubmitError,
} from '../../../form-common/types'

import translations from './AdyenMbwayAdapter.translations'

const i18n = useI18n()

const props = defineProps<PaymentFormAdapterProps>()
const emit = defineEmits<PaymentFormAdapterEmits>()

defineExpose<PaymentFormAdapterExpose>({
  loadingMessage: i18n(translations.waitingMessage),
})

const NEXT_ACTION_RETRY_DELAY = 3000
const MBWAY_TIMEOUT = 16 * 60 * 1000

const country = useMarketplace().market.countryCode

const initialValues = {
  phone: '',
}
const validate = makeValidate<typeof initialValues>({
  phone: {
    REQUIRED: required(i18n(translations.phoneRequired)),
    VALID: validPhoneNumber(i18n(translations.phoneInvalid)),
  },
})

const getNextAction = async (paymentId: string) => {
  let cancelled = false

  const nextActionTimeout = setTimeout(() => {
    cancelled = true
  }, MBWAY_TIMEOUT)

  try {
    do {
      // eslint-disable-next-line no-await-in-loop
      await sleep(NEXT_ACTION_RETRY_DELAY)

      // eslint-disable-next-line no-await-in-loop
      const payload = await $httpFetch(paymentAPI.getNextAction, {
        pathParams: {
          paymentId,
        },
      })

      if (payload.action !== 'RETRY') {
        return payload
      }
    } while (!cancelled)

    throw new Error('MBWay timeout while waiting for the next action')
  } finally {
    clearTimeout(nextActionTimeout)
  }
}

const handleSubmit = async ({ phone }: typeof initialValues) => {
  emit('submit-start')
  try {
    const { paymentId } = await props.createPayment({ phone_number: phone })

    const nextAction = await getNextAction(paymentId)

    if (nextAction.action === 'SHOW_ERROR') {
      emit(
        'submit-error',
        new PaymentSubmitError({
          cause: nextAction,
          readableMessage: {
            title: nextAction.data?.title ?? '',
            description: nextAction.data?.detail ?? '',
          },
        }),
      )
    } else {
      emit('submit-success', {
        paymentId,
        redirection: getRedirectionToPaymentResult(paymentId),
      })
    }
  } catch (error) {
    emit('submit-error', PaymentSubmitError.fromAnyError(error))
  }
}
</script>
