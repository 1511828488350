<template>
  <div class="mr-12 flex">
    <IconCreditCard
      v-if="!hasNetworks"
      aria-hidden
      :class="iconClassNames"
      data-test="generic-card-icon"
      height="20"
      width="20"
    />

    <RevIllustration
      v-if="hasSingleNetwork && buttons[0].icon"
      :alt="buttons[0].label"
      :height="26"
      :src="buttons[0].icon"
      :width="40"
    />

    <div
      v-if="hasMultipleNetworks"
      :aria-label="i18n(translations.radioGroupLabel)"
      class="flex flex-row-reverse gap-1"
      role="radiogroup"
    >
      <RevButtonBase
        v-for="{ id, label, icon } in buttons"
        :key="id"
        :aria-checked="id === modelValue"
        :class="[
          'flex',
          'bg-transparent',
          'cursor-pointer',
          'border',
          'rounded-[5px]',
          id === modelValue ? 'border-static-default-hi' : 'border-transparent',
        ]"
        role="radio"
        @click="handleButtonClick(id)"
      >
        <RevIllustration
          v-if="icon"
          :alt="label"
          :height="26"
          :src="icon"
          :width="40"
        />
      </RevButtonBase>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, watch } from 'vue'

import type { PaymentNetwork } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import { toPublicPath } from '@backmarket/nuxt-layer-base/utils/toPublicPath'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { tw } from '@backmarket/utils/string/tw'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevIllustration } from '@ds/components/Illustration'
import { IconCreditCard } from '@ds/icons/IconCreditCard'

import { PaymentLogTypes } from '../..'
import {
  paymentNetworkConfig,
  paymentNetworkIcon,
} from '../../../methods/helpers/methods'

import translations from './PaymentNetworkRadioGroup.translations'
import { sortPaymentNetworkByPreference } from './PaymentNetworkRadioGroup.utils'

const props = defineProps<{
  networks: PaymentNetwork[]
  preferredNetworks: PaymentNetwork[]
  modelValue: PaymentNetwork | null
  disabled: boolean
}>()
const emit = defineEmits<{
  'update:modelValue': [PaymentNetwork | null]
}>()

const i18n = useI18n()
const logger = useLogger()

const sortedNetworks = computed(() =>
  sortPaymentNetworkByPreference(props.networks, props.preferredNetworks),
)

const hasNetworks = computed(() => sortedNetworks.value.length > 0)

const hasSingleNetwork = computed(() => sortedNetworks.value.length === 1)

const hasMultipleNetworks = computed(
  () => hasNetworks.value && !hasSingleNetwork.value,
)

const isValueValid = computed(() =>
  hasNetworks.value
    ? props.modelValue !== null &&
      sortedNetworks.value.includes(props.modelValue)
    : props.modelValue === null,
)

const buttons = computed(() =>
  sortedNetworks.value.map((network) => {
    const icon = paymentNetworkIcon(network)

    return {
      id: network,
      label: i18n(paymentNetworkConfig(network).name),
      icon: icon ? toPublicPath(icon) : null,
    }
  }),
)

function enforceValueValidity() {
  // If the value is not valid, then we emit a valid 'input' event so
  // the consuming component can update it.
  if (!isValueValid.value) {
    const validValue = hasNetworks.value ? sortedNetworks.value[0] : null
    emit('update:modelValue', validValue)
  }
}

function handleButtonClick(network: PaymentNetwork) {
  emit('update:modelValue', network)

  logger.info(`[PAYMENT] User choose a preferred card network: ${network}`, {
    type: PaymentLogTypes.FORM_PREFERRED_CARD_NETWORK_SELECTED,
    payment: {
      network,
      preferredNetworks: props.preferredNetworks,
      availableNetworks: props.networks,
    },
  })
}

const iconClassNames = computed(() => [
  tw`pointer-events-none`,
  props.disabled
    ? tw`text-static-default-low-disabled`
    : tw`text-static-default-low`,
])

watch(() => props.networks, enforceValueValidity)
watch(() => props.modelValue, enforceValueValidity)
onMounted(enforceValueValidity)
</script>
