import type { Component } from 'vue'

import {
  PaymentIntegrationType,
  PaymentMethodCode,
  PaymentServiceProvider,
} from '@backmarket/http-api/src/api-specs-payment/payment'

import AdyenCardAdapter from '../../form-adyen/components/AdyenCardAdapter/AdyenCardAdapter.vue'
import AdyenHostedAdapter from '../../form-adyen/components/AdyenHostedAdapter/AdyenHostedAdapter.vue'
import AdyenKonbiniAdapter from '../../form-adyen/components/AdyenKonbiniAdapter/AdyenKonbiniAdapter.vue'
import AdyenMbwayAdapter from '../../form-adyen/components/AdyenMbwayAdapter/AdyenMbwayAdapter.vue'
import ApplePayAdapter from '../../form-apple-pay/components/ApplePayAdapter/ApplePayAdapter.vue'
import type { PaymentFormAdapterProps } from '../../form-common/types'
import ProcessOutCardAdapter from '../../form-processout/components/ProcessOutCardAdapter/ProcessOutCardAdapter.vue'
// TODO: [PAYIN-3548] The adapter could be lazy-loaded to avoid loading all the adapters at once.
// Especially when adapters are country-specific.
import PassThroughAdapter from '../components/PassThroughAdapter/PassThroughAdapter.vue'

// TODO, replace each adapter by the real one, once they are implemented
const CompatibilityAdapter = PassThroughAdapter
const ProcessOutHostedAdapter = PassThroughAdapter

type Adapter = Component<PaymentFormAdapterProps>
type ConfigLevel1 = Record<PaymentServiceProvider, ConfigLevel2>
type ConfigLevel2 = Partial<Record<PaymentMethodCode, ConfigLevel3>> & {
  DEFAULT: ConfigLevel3
}
type ConfigLevel3 = Partial<Record<PaymentIntegrationType, Adapter>> & {
  DEFAULT: Adapter
}

const ADYEN_COMMON = {
  [PaymentMethodCode.CARD]: {
    [PaymentIntegrationType.ADYEN_CHECKOUT_API]: AdyenCardAdapter,
    DEFAULT: CompatibilityAdapter,
  },
  [PaymentMethodCode.APPLE_PAY]: {
    DEFAULT: ApplePayAdapter,
  },
  [PaymentMethodCode.KONBINI_GENERIC]: {
    DEFAULT: AdyenKonbiniAdapter,
  },
  [PaymentMethodCode.MBWAY]: {
    DEFAULT: AdyenMbwayAdapter,
  },
  DEFAULT: {
    [PaymentIntegrationType.ADYEN_CHECKOUT_API]: AdyenHostedAdapter,
    DEFAULT: PassThroughAdapter,
  },
}

export const PAYMENT_FORM_ADAPTER_NOT_REQUIRED = PassThroughAdapter

export const PAYMENT_FORM_ADAPTERS: ConfigLevel1 = {
  [PaymentServiceProvider.ADYEN_PAYIN_PAYOUT]: ADYEN_COMMON,
  [PaymentServiceProvider.ADYEN_MARKETPAY]: ADYEN_COMMON,

  [PaymentServiceProvider.PAYPAL]: {
    DEFAULT: {
      DEFAULT: PassThroughAdapter,
    },
  },

  [PaymentServiceProvider.PROCESSOUT]: {
    [PaymentMethodCode.CARD]: {
      DEFAULT: ProcessOutCardAdapter,
    },
    [PaymentMethodCode.APPLE_PAY]: {
      DEFAULT: ApplePayAdapter,
    },
    DEFAULT: {
      DEFAULT: ProcessOutHostedAdapter,
    },
  },
}
