import type { CardForm } from '../../../processout'
import type { VirtualCardFieldIds } from '../../form-common/types/VirtualCardFieldIds'
import type { ProcessOutCardFieldIds } from '../types/ProcessOutCardFieldIds'

type FormGetter = () => CardForm | null

export const getProcessOutId = (
  id: VirtualCardFieldIds,
): ProcessOutCardFieldIds => {
  switch (id) {
    case 'number':
      return 'cc-number'
    case 'expiryDate':
      return 'cc-exp'
    case 'securityCode':
      return 'cc-cvc'
    default:
      return 'name'
  }
}

export const requestFocus = (id: VirtualCardFieldIds, getForm: FormGetter) => {
  switch (getProcessOutId(id)) {
    case 'cc-number':
      getForm()?.getNumberField().focus()
      break
    case 'cc-cvc':
      getForm()?.getCVCField().focus()
      break
    case 'cc-exp':
      getForm()?.getExpiryField().focus()
      break
    default:
      break
  }
}
