import { useLazyAsyncData } from '#imports'
import { type MaybeRef, toRef } from 'vue'

import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment'

import { PAYMENT_METHOD_CONFIGS } from '../config/methods'

async function isPaymentMethodAvailable(paymentMethod: PaymentMethod) {
  const isAvailableInBrowser =
    PAYMENT_METHOD_CONFIGS[paymentMethod.bmCode]?.isAvailableInBrowser ||
    (() => true)

  return process.server || isAvailableInBrowser(paymentMethod.config || {})
}

/**
 * Filter a list of payment methods, and retain only those that are available
 * in the user's browser. It relies on `isAvailableInBrowser` from
 * `PAYMENT_METHOD_CONFIGS`, or consider it is always available if not defined.
 * Note that it will return the same list on server-side.
 */
export async function filterAvailablePaymentMethods(
  paymentMethods: PaymentMethod[],
) {
  const availability = await Promise.all(
    paymentMethods.map(isPaymentMethodAvailable),
  )

  return paymentMethods.filter((_, i) => availability[i])
}

/**
 * Get the payment methods available in the current browser context.
 * This will only resolve in the browser and keep pending on the server.
 */
export const useAvailablePaymentMethods = (
  paymentMethods: MaybeRef<PaymentMethod[]>,
) => {
  const asyncData = useLazyAsyncData(
    () => filterAvailablePaymentMethods(toRef(paymentMethods).value),
    {
      server: false,
      default: () => paymentMethods,
    },
  )

  return { ...asyncData, availablePaymentMethods: asyncData.data }
}
